import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AsyncSelect({ data, label, value, setValue, multiple }) {
  const selectedValues = React.useMemo(
    () => multiple ?  value?.filter((v) => v.selected) : '',
    [value],
  );
  return (
    <>
      <Autocomplete
        multiple={multiple}
        disablePortal
        value={value ? value : ''}
        onChange={(event, newValue) => {
          console.log(newValue)
          setValue(newValue);
        }}
        id="combo-box-demo"
        options={data}
        fullWidth
        // onSelect={(e) => setSelected(e.target.value)}
        defaultValue={value}
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    </>
  );
}