import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomePage from './Pages/Home/HomePage';
import Login from './Auth/Login/Login';
import Register from './Auth/Register/Register';
import Cart from './Pages/Cart/Cart';
import ProductDetail from './Pages/Detail/ProductDetail';
import SingleCategory from './SingleCategory/SingleCategory';
import MobileNavigation from './Navigation/MobileNavigation';
import DesktopNavigation from './Navigation/DesktopNavigation';
import Wishlist from './Pages/WhisList/Wishlist';
import PaymentSuccess from './Pages/Payment/PaymentSuccess';
import { Flip, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CheckoutForm from './Components/Checkout/CheckoutForm';
import UpdateDetails from './Pages/Update_User/UpdateDetails';
import ForgotPasswordForm from './Auth/ForgotPassword/ForgotPasswordForm';
import AddNewPassword from './Auth/ForgotPassword/AddNewPassword';
import AdminLogin from './Admin/Auth/Login/AdminLogin';
import AdminRegister from './Admin/Auth/Register/AdminRegister';
import AdminHomePage from './Admin/Pages/AdminHomePage';
import SingleUserPage from './Admin/Pages/SingleUserPage';
import SingleProduct from './Admin/Pages/SingleProduct';
import { ThemeProvider, createTheme } from '@mui/material';
import { blue } from '@mui/material/colors';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import ShippingAndReturnPolicy from './Pages/ShippingAndReturnPolicy/ShippingAndReturnPolicy';
import { getStoreData } from './Constants/Constant';
import { useEffect, useState } from 'react';
import { initFacebookPixel }  from './facebookPixel';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[300],
    },
  },
});


function App() {
  const [inputs, setInputs] = useState({pixel : null});

  useEffect(() => {
    const fetchPixelId = async () => {
      try {
        await getStoreData(setInputs); // Replace with your backend endpoint
        console.log(inputs)
        if (!inputs.pixel) {
          throw new Error('Pixel ID is null or undefined');
        }
      } catch (error) {
        console.error('Error fetching the Pixel ID:', error);
      }
    };

    fetchPixelId();
  }, []);

  useEffect(() => {
    if (inputs.pixel) {
      initFacebookPixel(inputs.pixel);
    }
  }, [inputs.pixel]);
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer toastClassName='toastContainerBox' transition={Flip} position='top-center' rtl />
      <Router>
        <DesktopNavigation />
        <div className='margin'>
          <Routes>
            {/*User Routes  */}
            <Route path='/' index element={<HomePage />} />
            <Route path="/login" element={< AdminLogin />} />
            <Route path='/Detail/type/:cat/:id' element={<ProductDetail />} />
            <Route path='product/type/:cat' element={<SingleCategory />} />
            <Route path='/update' element={<UpdateDetails />} />
            <Route path='/paymentsuccess' element={<PaymentSuccess />} />
            <Route path='/forgotpassword' element={<ForgotPasswordForm />} />
            <Route path='/user/reset/:id/:token' element={<AddNewPassword />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path="/shipping-return-policy" element={<ShippingAndReturnPolicy />} />

            {/* Admin Routes */}
            <Route path="/admin/login" element={< AdminLogin />} />
            <Route path='/admin/register' element={<AdminRegister />} />
            <Route path='/admin/home' element={<AdminHomePage />} />
            <Route path='/admin/home/user/:id' element={<SingleUserPage />} />
            <Route path='/admin/home/product/:type/:id' element={<SingleProduct />} />
          </Routes>
        </div>
      </Router >


    </ThemeProvider>
  );
}
export default App;
