// src/ShippingAndReturnPolicy.js
import React from 'react';

const ShippingAndReturnPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>سياسة الشحن والإرجاع</h1>
      
      <h2 style={styles.subHeading}>سياسة الشحن</h2>
      <p>{`نحن في ${process.env.REACT_APP_STORE_NAME} نلتزم بتوفير تجربة شحن سلسة وسريعة لعملائنا. فيما يلي تفاصيل سياسة الشحن الخاصة بنا:`}</p>
      <ul>
        <li>نحن نقدم الشحن لجميع أنحاء البلاد.</li>
        <li>سيتم معالجة الطلبات خلال 1-2 أيام عمل بعد تأكيد الطلب.</li>
        <li>قد تختلف مدة التوصيل بناءً على موقعك، ولكنها عادة ما تتراوح بين 3-7 أيام عمل.</li>
        <li>سيتم إرسال رسالة بريد إلكتروني تحتوي على معلومات التتبع بمجرد شحن طلبك.</li>
      </ul>

      <h2 style={styles.subHeading}>سياسة الإرجاع</h2>
      <p>رضا العملاء هو أولويتنا القصوى. إذا كنت غير راضٍ عن مشترياتك، يمكنك إرجاعها وفقًا للشروط التالية:</p>
      <ul>
        <li>يمكنك إرجاع المنتجات خلال 30 يومًا من تاريخ الاستلام.</li>
        <li>يجب أن تكون المنتجات في حالتها الأصلية وغير مستخدمة، مع جميع الملحقات والتغليف الأصلي.</li>
        <li>يرجى الاتصال بفريق خدمة العملاء لدينا لبدء عملية الإرجاع.</li>
        <li>قد تتحمل تكلفة شحن الإرجاع، إلا في حالة استلام منتج معيب أو خطأ في الطلب.</li>
      </ul>

     
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    direction: 'rtl',
    textAlign: 'right',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
  },
  heading: {
    color: '#333',
  },
  subHeading: {
    color: '#333',
  },
};

export default ShippingAndReturnPolicy;
