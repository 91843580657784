import React, { useEffect, useState } from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, Backdrop, CircularProgress, Rating,
    OutlinedInput,
    Chip,
    InputAdornment,
    IconButton,
    ButtonGroup,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Transition } from '../../Constants/Constant';
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import wilaya from "../../Assets/wilaya.json";
import communes from "../../Assets/communes.json";
import { useTheme } from 'styled-components';
import { FcPhone } from 'react-icons/fc';
import AsyncSelect from './AsyncSelect/AsyncSelect';
const arabic = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\u0660-\u0669\s]+$/;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        }
    }
};
var phoneregex = /^(00213|\+213|0)(5|6|7)[0-9]{8}$/
function getStyles(name, theme) {
    return {
        fontWeight:
            theme?.typography?.fontWeightMedium
    };
}
const AddOrder = ({ open, setOpen, getOrders, data, title, categorylabels }) => {
    const theme = useTheme();
    const [wilay, setWilay] = useState('1');
    const [wilayas, setWilayas] = useState('');
    const [commune, setCommune] = useState('');
    const [order, setOrder] = useState({
        name: '', address: '', commune: '', wilaya: '', quantity: 0, product: '', totalAmount: 0, phoneNumber: '',
        open: false, id: '', description: '', product: '', variant: []
    });
    const [products, setProducts] = useState([]);
    const [variants, setVariants] = useState([]);
    const handleChangeSelect = (event) => {
        const {
            target: { value },
        } = event;
        const {
            target: { name },
        } = event;
        if (name === 'wilaya') {
            setWilayas([value]);
            if (value) {
                const wil = wilaya.filter((val) => val.ar_name === value);
                if (wil?.length) {
                    setWilay(wil[0].id);
                }
            }
        }
        else if (name == 'commune')
            setCommune([value]);
    };
    const handleOnchange = (e) => {
        setOrder({ ...order, [e.target.name]: e.target.value });
    }
    const handleClose = () => {
        setOpen(false);
        setOrder({
            name: '', address: '', commune: '', wilaya: '', quantity: 0, product: '', totalAmount: 0,
            phoneNumber: '', open: false, id: '', description: '', product: '', variant: []
        });
        setCommune([]);
        setWilayas([]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setOrder({ ...order, open: true });
        try {
            if (!order.name || !order.phoneNumber || !commune || commune?.length < 1 || !order.product) {
                toast.warning("الرجاء إكمال المعلومات", { autoClose: 500, theme: 'colored' });
            } else {
                const { data } = await axios.post(`${process.env.REACT_APP_BUY_PRODUCT}`, {
                    phoneNumber: order.phoneNumber, quantity: order.quantity, name: order.name,id : order.id,
                    product: { id: order.product?._id, variant: order?.product?.label + ', ' + order.variant?.toString() }
                    , address: wilayas[0] + ' , ' + commune[0], commune: commune[0], wilaya: wilayas[0], totalAmount: order.totalAmount
                    , description : order.description
                });
                if (data.success) {
                    getOrders();
                    setOpen(false);
                    toast.success("تم إضافة الطلبية بنجاح", { autoClose: 500, theme: 'colored' });
                    setOrder({
                        name: '', address: '', commune: '', wilaya: '', quantity: 0, product: '', totalAmount: 0, phoneNumber: '',
                        open: false, id: '', description: '', product: '', variant: []
                    });
                }
                else {
                    toast.error('يوجد خلل', { autoClose: 500, theme: 'colored' });
                }
            }
        } catch (error) {
            console.log(error);
            setOrder({ ...order, open: false });
            toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' });
        }
    }
    useEffect(() => {
        getOrderById(data?._id);
    }, [data?._id]);
    useEffect(() => {
        getProducts();
    }, []);
    const getProducts = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_FETCH_PRODUCTS_NAME);
            setProducts(data);
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getProductsVariant();
    }, [order.product]);
    const getProductsVariant = async () => {
        if (order?.product?._id) {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCTS_VARIANT}/${order?.product?._id}`);
                if (data && data?.length > 0) {
                    const n = data.length;
                    const variants = [];
                    for (let i = 0; i < n; i++) {
                        const k = data[i]?.variants?.length;
                        for (let j = 0; j < k; j++) {
                            variants.push({ label: data[i]?.variants[j], _id: i });
                        }
                    }
                    setVariants(variants);
                }

            } catch (error) {
                console.log(error);
            }
        }
    }
    const getOrderById = async (id) => {
        if (id) {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_FETCH_ORDER}/${id}`);
                setOrder({ ...data, category: data.name_fr + ',' + data.name });
                setWilayas([data?.wilaya]);
                setCommune([data?.commune]);
            } catch (error) {
                console.log(error);
            }
        }
    }
    const increaseQuantity = () => {
        const qte = order.quantity + 1;
        if (qte >= 20) {
            setOrder({ ...order, quantity: 20 });
        } else
            setOrder({ ...order, quantity: qte });
    }
    const decreaseQuantity = () => {
        const qte = order.quantity - 1;
        if (qte <= 1) {
            setOrder({ ...order, quantity: 20 });
        }
        else {
            setOrder({ ...order, quantity: qte });
        }
    }
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                open={order.open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Divider sx={{ mb: 5 }} />
            <Dialog style={{ minWidth: '300px' }}
                open={open}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required variant="outlined" error={order.phone && !phoneregex.test(order.phone)} >
                                        <OutlinedInput onChange={handleOnchange} name="phoneNumber" value={order.phoneNumber} onBlur={() => {
                                            if (!phoneregex.test(order.phoneNumber)) {
                                                toast.error('يرجى إستعمال رقم هاتف صحيح', { autoClose: 500, theme: 'colored', position: "bottom-center" });
                                            }
                                        }}
                                            id="outlined-adornment-password"
                                            type='number'
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => console.log('mlazkelmakz')}
                                                        edge="start"
                                                    >
                                                        <FcPhone />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                        <InputLabel htmlFor="outlined-adornment-password">رقم الهاتف</InputLabel>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField dir='rtl' label="الإسم" name='name' value={order.name} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth required >
                                        <InputLabel id="chip01">الولاية</InputLabel>
                                        <Select
                                            labelId="chip1-label"
                                            id="chip1"
                                            name='wilaya'
                                            value={wilayas}
                                            onChange={handleChangeSelect}
                                            input={<OutlinedInput id="select-chip1" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected?.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {wilaya.map((val) => (
                                                <MenuItem
                                                    key={val.id}
                                                    value={val.ar_name}
                                                    style={getStyles(val.ar_name, theme)}
                                                >
                                                    {val.code + ' - ' + val.name + ' - ' + val.ar_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth >
                                        <InputLabel id="chip02">البلدية</InputLabel>
                                        <Select readOnly={!wilayas}
                                            labelId="chip2-label"
                                            id="chip2"
                                            name='commune'
                                            value={commune}
                                            onChange={handleChangeSelect}
                                            input={<OutlinedInput id="select-chip2" label="Chip" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected?.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={MenuProps}
                                        >
                                            {communes.filter((val) => val.wilaya_id == wilay).map(({ id, ar_name, name, post_code }, index) => (

                                                <MenuItem
                                                    key={index}
                                                    value={ar_name}
                                                    style={getStyles(ar_name, theme)}
                                                >
                                                    {name + ' - ' + ar_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div dir='ltr'  >
                                        <ButtonGroup size='large' variant="outlined" aria-label="outlined button group" style={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder' }}  >
                                            <Button style={{ fontWeight: 'bolder', fontSize: '22px' }} onClick={increaseQuantity}>+</Button>
                                            <Button style={{ fontWeight: 'bolder', fontSize: '16px' }} >{order.quantity}</Button>
                                            <Button style={{ fontWeight: 'bolder', fontSize: '22px' }} onClick={decreaseQuantity}>-</Button>
                                        </ButtonGroup>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="الثمن الإجمالي" name='totalAmount' value={order.totalAmount} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AsyncSelect value={order.product} label='المنتج' data={products} setValue={(val) => setOrder({ ...order, product: val })} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <AsyncSelect multiple={true} value={order?.variant} label='أصناف المنتج' data={variants} setValue={(val) => {
                                        setOrder({ ...order, variant: val })
                                    }} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField dir='rtl' multiline rows={2} label="معلومات إضافية للطلبية" name='description' value={order.description} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                            </Grid>
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button style={{ color: 'white' }} fullWidth variant='contained' type='reset' color='error' onClick={handleClose} startIcon={<MdOutlineCancel color='white' />}>إلفاء</Button>
                                <Button disabled={order.open || !order.name || !order.phoneNumber || !commune || commune?.length < 1} style={{ color: 'white' }} type="submit" fullWidth variant="contained" startIcon={<MdProductionQuantityLimits color='white' />}>تأكيد</Button>
                            </DialogActions>
                        </form>
                    </Box >
                </DialogContent>
            </Dialog >
        </>
    )
}
export default AddOrder;