import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai';
import {
  Table, Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Container,
  InputAdornment,
  TextField, Card, Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Backdrop, CircularProgress, Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  Select,
  Chip,
  MenuItem
}
  from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Transition } from '../../../Constants/Constant';
import wilaya from "../../../Assets/wilaya.json";
import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from './scrollbar';
import AddOrder from '../AddOrder';
import TableNoData from './tools/table-no-data';
import OrderTableRow from './tools/order-table-row';
import OrderTableHead from './tools/order-table-head';
import TableEmptyRows from './tools/table-empty-rows';
import { emptyRows, applyFilter, getComparator } from './tools/utils';
import { MdAdd, MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import Iconify from './iconify';
import { FontSize } from 'mui-tiptap';
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group';
import { FcPhone } from 'react-icons/fc';
import AsyncSelect from '../AsyncSelect/AsyncSelect';
import { useTheme } from 'styled-components';

var phoneregex = /^(00213|\+213|0)(5|6|7)[0-9]{8}$/
function getStyles(name, theme) {
  return {
    fontWeight:
      theme?.typography?.fontWeightMedium
  };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    }
  }
};
const OrderTable = ({ data, getOrders, categorylabels }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [wilay, setWilay] = useState('1');
  const [wilayas, setWilayas] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [openupdate, setOpenUpdate] = useState(false);
  const [openvariant, setOpenVariant] = useState(false);
  const [dataItem, setDataItem] = useState();
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({ minOrderDate: '', maxOrderDate: '', wilaya: '', product: '' })
  const [products, setProducts] = useState([]);
  const handleChangeSelect = (event) => {
    const {
      target: { value },
    } = event;
    const {
      target: { name },
    } = event;
    if (name === 'wilaya') {
      setWilayas([value]);
      if (value) {
        const wil = wilaya.filter((val) => val.ar_name === value);
        if (wil?.length) {
          setFilter({ ...filter, wilaya: wil[0].ar_name });
        }
      }
    }
  };
  const handleOnchange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }
  const filterData = () => {
    if (searchTerm === '') {
      return data;
    }
    return data?.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.phoneNumber &&
          item.phoneNumber == searchTerm) ||
        (item.product?.variant &&
          item.product?.variant?.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.createdAt &&
          (new Date(item.createdAt)?.toLocaleDateString() === searchTerm
            || new Date(item.createdAt)?.toLocaleDateString()?.slice(0, 2) === searchTerm
            || new Date(item.createdAt)?.toLocaleDateString()?.slice(0, 5) === searchTerm
          )

        )
    );
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const newFilteredData = filterData();
    setFilteredData(newFilteredData);
  };

  useEffect(() => {
    setFilteredData(filterData());
  }, [data, searchTerm]);

  useEffect(() => {
    getProducts();
  }, []);
  const getProducts = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_FETCH_PRODUCTS_NAME);
      setProducts(data);
    } catch (error) {
      console.log(error);
    }
  }


  const [open, setOpen] = useState(false);
  const [id_delete, setIdDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('تأكيد حذف الطلبية');
  const [operation, setOperation] = useState(1) // 1 is delete 2 is for confirmoing order & 3 for confirming the deliveries
  const [value, setValue] = useState(false);

  const [selected, setSelected] = useState([]);

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !filteredData?.length;
  const deleteItem = (id) => {
    setDialogTitle('تأكيد حذف الطلبية');
    setOperation(1);
    setOpen(true);
    setIdDelete(id);
  }
  const openFilterDialog = () => {
    setDialogTitle("تحميل ملف إكسل");
    setOpen(true);
    setOperation(6);
  }
  const confirmOrderDialog = (val, id) => {
    setDialogTitle(val ? " تأكيد الطلبية " : " إلغاء تأكيد الطلبية ");
    setOpen(true);
    setValue(val);
    setOperation(2);
    setIdDelete(id);
  }
  const confirmDeliveryDialog = (val, id) => {
    setDialogTitle(val ? " تأكيد توصيل الطلبية " : " إلغاء توصيل الطلبية ");
    setOpen(true);
    setOperation(3);
    setIdDelete(id);
  }

  const setReturnOrder = (id) => {
    setDialogTitle(" تأكيد إسترجاع الطلبية");
    setOpen(true);
    setOperation(5);
    setIdDelete(id);
  }
  const confirmClientCall = (id) => {
    setDialogTitle(" تأكيد عدم إستجابة الزبون للهاتف");
    setOpen(true);
    setOperation(4);
    setIdDelete(id);
  }
  const deleteOrder = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_DELETE_ORDER,
        {
          id: id_delete
        }, {
        headers: {
          'Authorization': localStorage.getItem("Authorization")
        }
      })
      setLoading(false);
      if (data?.success) {
        getOrders();
        toast.success("تم الحذف بنجاح", { autoClose: 500, theme: 'colored' });
        setOpen(false);
      }
      else {
        toast.error(data?.msg, { autoClose: 500, theme: 'colored' });
      }


    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem('Authorization')
        toast.success("Logout Successfully", { autoClose: 500, theme: 'colored' })
        navigate('/')
      } else
        toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }


  const downloadExcelFile = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_GENERATE_ORDERS_EXCEL,
        {
          filter
        },
        {
          headers: {
            'Authorization': localStorage.getItem("Authorization")
          },
          responseType: "blob"
        })
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${process.env.REACT_APP_STORE_NAME}.xlsx`
      );
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
      setOpen(false);
      setFilter({ minOrderDate: '', maxOrderDate: '', wilaya: '', product: '' });
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }
  const setOrdersReturn = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_SET_ORDERS_RETURN,
        {
          id: id_delete
        }, {
        headers: {
          'Authorization': localStorage.getItem("Authorization")
        }
      })
      setLoading(false);
      if (data?.success) {
        getOrders();
        toast.success("تمت العملية بنجاح", { autoClose: 500, theme: 'colored' });
        setOpen(false);
      }
      else {
        toast.error(data?.msg, { autoClose: 500, theme: 'colored' });
      }


    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }
  const setClientDidntPickUp = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_CLIENT_DIDNTPICKUP_CALL,
        {
          id: id_delete
        }, {
        headers: {
          'Authorization': localStorage.getItem("Authorization")
        }
      })
      setLoading(false);
      if (data?.success) {
        getOrders();
        toast.success("تمت العملية بنجاح", { autoClose: 500, theme: 'colored' });
        setOpen(false);
      }
      else {
        toast.error(data?.msg, { autoClose: 500, theme: 'colored' });
      }


    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }
  const confirmOrder = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_CONFIRM_ORDER,
        {
          id: id_delete, value: value
        }, {
        headers: {
          'Authorization': localStorage.getItem("Authorization")
        }
      })
      setLoading(false);
      if (data?.success) {
        getOrders();
        toast.success(value ? "تم تأكيد الطلبية بنجاح" : "تم إلغاء تأكيد الطلبية بنجاح", { autoClose: 500, theme: 'colored' });
        setOpen(false);
      }
      else {
        toast.error(data?.msg, { autoClose: 500, theme: 'colored' });
      }


    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }
  const confirmDelivery = async () => {
    setLoading(true);
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_DELIVER_ORDER,
        {
          id: id_delete, value: value
        }, {
        headers: {
          'Authorization': localStorage.getItem("Authorization")
        }
      })
      setLoading(false);
      if (data?.success) {
        getOrders();
        toast.success(value ? "تم تأكيد توصيل الطلبية بنجاح" : "تم إلغاء توصيل الطلبية بنجاح", { autoClose: 500, theme: 'colored' });
        setOpen(false);
      }
      else {
        toast.error(data?.msg, { autoClose: 500, theme: 'colored' });
      }


    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }
  const handleClose = () => {
    setOpen(false);
    setFilter({ minOrderDate: '', maxOrderDate: '', wilaya: '', product: '' });
  };
  const setData = (data) => {
    setDataItem(data);
    setOpenUpdate(true);
  }
  const handleClickOpen = () => {
    setDataItem('');
    setOpenUpdate(true);
  };


  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginTop: 5 }}>
        <IconButton aria-label="delete" size="large" onClick={() => openFilterDialog()} >
          <Iconify
            icon="vscode-icons:file-type-excel"
            style={{ width: '48px', height: '48px' }} // Adjust the size as needed
          />
        </IconButton>
        <TextField dir='rtl'
          id="search"
          type="search"
          label="البحث عن الطلبيات"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: { xs: 350, sm: 500, md: 800 }, }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: "20px 0" }} >
        <Button style={{ color: 'white' }} variant='contained' startIcon={<MdAdd color='white' />} onClick={handleClickOpen}>إضافة</Button>
        <Typography fontSize={28} variant='h6' textAlign='center' color="#1976d2" fontWeight="bold">{'إضافة طلبية جديدة'}</Typography>
      </Box>
      <AddOrder categorylabels={categorylabels} open={openupdate} setOpen={setOpenUpdate} getOrders={getOrders} data={dataItem} title={!dataItem ? 'إضافة طلبية جديدة' : 'تحيين الطلبية'} />
      <Dialog style={{ minWidth: '300px' }}
        open={open}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Transition}>
        <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <form onSubmit={(e) => {
              e.preventDefault();
              if (operation === 1) {
                deleteOrder();
              } else if (operation === 2) {
                confirmOrder();
              } else if (operation === 3) {
                confirmDelivery();
              } else if (operation === 4) {
                setClientDidntPickUp();
              } else if (operation === 5) {
                setOrdersReturn();
              } else if (operation === 6) {
                downloadExcelFile();
              }
            }} noValidate>
              {operation === 6 && <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField type='date' dir='rtl' label="تاريخ بداية الطلبيات" name='minOrderDate' value={filter.minOrderDate} onChange={handleOnchange} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField type='date' dir='rtl' label="تاريخ نهاية الطلبيات" name='maxOrderDate' value={filter.maxOrderDate} onChange={handleOnchange} variant="outlined" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth required >
                    <InputLabel id="chip01">الولاية</InputLabel>
                    <Select
                      labelId="chip1-label"
                      id="chip1"
                      name='wilaya'
                      value={wilayas}
                      onChange={handleChangeSelect}
                      input={<OutlinedInput id="select-chip1" label="Chip" />}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected?.map((value) => (
                            <Chip key={value} label={value} />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {wilaya.map((val) => (
                        <MenuItem
                          key={val.id}
                          value={val.ar_name}
                          style={getStyles(val.ar_name, theme)}
                        >
                          {val.code + ' - ' + val.name + ' - ' + val.ar_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AsyncSelect value={filter.product} label='المنتج' data={products} setValue={(val) => setFilter({ ...filter, product: val })} />
                </Grid>
              </Grid>}
              <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                <Button style={{ color: 'white' }} fullWidth variant='contained' type='reset' color='error' onClick={handleClose} startIcon={<MdOutlineCancel color='white' />}>إلفاء</Button>
                <Button style={{ color: 'white' }} type="submit" fullWidth variant="contained">تأكيد</Button>
              </DialogActions>

            </form>
          </Box >

        </DialogContent>
      </Dialog >
      <Card>

        <Scrollbar>
          <TableContainer  >
            <Table sx={{ minWidth: 800 }}>
              <OrderTableHead
                order={order}
                orderBy={orderBy}
                rowCount={data?.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}

                headLabel={[
                  { id: 'name', label: 'الإسم', align: 'right' },
                  { id: 'address', label: 'العنوان', align: 'right' },
                  { id: 'phoneNumber', label: 'رقم الهاتف', align: 'center' },
                  { id: 'product', label: 'المنتج', align: 'center' },
                  { id: 'quantity', label: 'الكمية', align: 'center' },
                  { id: 'createdAt', label: 'تاريخ الطلبية', align: 'center' },
                  { id: 'totalAmount', label: 'السعر الإجمالي', align: 'center' },
                  { id: 'confirmedBy', label: 'تأكيد الطلبية من طرف', align: 'left' },

                  { id: 'isConfirmed', label: 'تأكيد الطلبية', align: 'right' },
                  { id: 'isDelivered', label: 'تأكيد التوصيل', align: 'right' },
                  { id: '' },
                ]}
              />
              <TableBody>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row) => (
                    <OrderTableRow
                      key={row.id}
                      name={row.name}
                      address={row.address}
                      phoneNumber={row.phoneNumber}
                      product={row.productDetails[0]?.name_fr}
                      productsurl={`${window.location.origin}/Detail/type/${row.productDetails[0]?.category}/${row.productDetails[0]?._id} `}
                      quantity={row.quantity}
                      isConfirmed={row.isConfirmed}
                      isDelivered={row.isDelivered}
                      totalAmount={row.totalAmount}
                      confirmedBy={row.confirmedBy}
                      orderReturned={row.orderReturned}
                      createdAt={row.createdAt}
                      clientDidntPickedUp={row.clientDidntPickedUp}
                      setClientDidntPickUp={confirmClientCall}
                      setReturnOrder={setReturnOrder}
                      _id={row?._id}
                      deleteItem={deleteItem}
                      confirmDelivery={confirmDeliveryDialog}
                      confirmOrder={confirmOrderDialog}
                      setData={setData}
                      selected={selected.indexOf(row.name) !== -1}
                      handleClick={(event) => handleClick(event, row.name)}
                    />
                  ))}

                <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, data?.length)}
                />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
            <TablePagination
              page={page}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  )
}

export default OrderTable