import React, { useEffect, useState } from 'react'
import {
    IconButton,
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, Backdrop, CircularProgress, Rating,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Transition, convertToBase64 } from '../../Constants/Constant';
import { MdAdd, MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import MyPreviewDropZone from '../../Components/MyPreviewDropZone/MyPreviewDropZone';
import AsyncSelect from './AsyncSelect/AsyncSelect';
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
import ChipsInput from './ChipInput';
import AddVariantButton from './AddVariantButton/AddVariantButton';
import { RiDeleteBin6Line } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';

const arabic = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\u0660-\u0669\s]+$/;
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const AddProductsVariant = ({ open, setOpen, title, _id }) => {
    const navigate = useNavigate();
    const [openBackDrop, setOpenBackDrop] = useState(false);
    const [chips, setChips] = useState([]);
    // const [open, setOpen] = useState(false);

    let authToken = localStorage.getItem("Authorization")
    const [variants, setVariants] = useState([{
        name: '', name_fr: '', variants: []
    }]);

    const handleOnChange = (index, event) => {
        const { name, value } = event.target;
        const newVariants = [...variants];
        newVariants[index][name] = value;

            setVariants(newVariants);
    };
    const handleOnChangeChips = (index, val, op) => {

        if (op === 1) {
            const newVariants = [...variants];
            newVariants[index].variants = [...newVariants[index].variants, ...val];
            setVariants(newVariants);
        } else if (op === 2) {
            const newVariants = [...variants];
            newVariants[index].variants = newVariants[index].variants?.filter((chip) => chip !== val)
            setVariants(newVariants);
        }
    };
    const handleClose = () => {
        setOpen(false);
        /*setVariants([{
            name: '', name_fr: '', variants: []
        }]);*/
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(variants);
        setOpenBackDrop(true);
        try {

            const { data } = await axios.post(process.env.REACT_APP_ADMIN_ADD_PRODUCTS_VARIANT,
                {
                    variants: variants,
                    id: _id
                }, {
                headers: {
                    'Authorization': localStorage.getItem("Authorization")
                }
            })
            setOpenBackDrop(false);
            if (data.success) {
                setOpen(false);
                setOpenBackDrop(false);
                getProductsVariantById(_id);
                toast.success("تم إضافة الصنف بنجاح", { autoClose: 500, theme: 'colored' });
            }
            else {
                toast.error(data.msg, { autoClose: 500, theme: 'colored' });
            }
        } catch (error) {
            setOpenBackDrop(false);
            console.log(error);
            if(error.response.status === 401) {
                localStorage.removeItem('Authorization')
                toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
                navigate('/')
            } else
            toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
        }
    }
    useEffect(() => {
        getProductsVariantById()
    }, [_id])
    const getProductsVariantById = async () => {
        if (_id) {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCTS_VARIANT}/${_id}`);
                setVariants(data);
            } catch (error) {
                console.log(error);
            }
        }
    }
    const handleAddVariant = () => {
        setVariants([...variants, { name: '', name_fr: '', variants: [] }]);
    };
    const handleDeleteVariant = (name) => {
        const variantes = [];
        const n =variants.length;
        for(let i = 0; i < n;i++) {
            if(variants[i].name !== name) {
                variantes.push(variants)
            }
        }
        setVariants(variantes);
    };
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                open={openBackDrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Divider sx={{ mb: 5 }} />
            <Dialog style={{ minWidth: '300px' }}
                open={open}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>{title}</DialogTitle>
                <DialogContent style={{ marginTop: '8px' }} >
                    <Box sx={{ mt: 2 }}>
                        <form onSubmit={handleSubmit}>
                            {variants.map((variant, index) => (
                                <Grid mt={2} container spacing={2} key={index}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            dir='rtl'
                                            label="التسمية"
                                            name='name'
                                            value={variant.name}
                                            onChange={(e) => handleOnChange(index, e)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="libellé"
                                            name='name_fr'
                                            value={variant.name_fr}
                                            onChange={(e) => handleOnChange(index, e)}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                        <IconButton aria-label="delete" color="primary" style ={{marginTop:'6px'}} onClick={() => handleDeleteVariant(variant.name)} >
                                            <RiDeleteBin6Line color='#d11a2a' size = '1.2em' />
                                        </IconButton>

                                    </Grid>
                                    <Grid item xs={12} sm={11}>
                                        <ChipsInput chips={variant.variants} setChips={(val, op) => {
                                            handleOnChangeChips(index, val, op)
                                        }} />
                                    </Grid>

                                </Grid>
                            ))}
                            <Grid item mt={2} xs={12} sm={6} display="flex" justifyContent="center" alignItems="center">
                                <AddVariantButton title={' إضافة صنف'} onClicked={handleAddVariant} />

                            </Grid>
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button color='error' style={{ color: 'white' }} fullWidth variant='contained' type='reset' onClick={handleClose} startIcon={<MdOutlineCancel color='white' />}>إلفاء</Button>
                                <Button disabled = {openBackDrop} style={{ color: 'white' }} type="submit" fullWidth variant="contained" startIcon={<MdProductionQuantityLimits color='white' />}>تأكيد</Button>
                            </DialogActions>

                        </form>
                    </Box >

                </DialogContent>
            </Dialog >
        </>
    )
}

export default AddProductsVariant