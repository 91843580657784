import React, { useState } from 'react';
import { Box, TextField, Chip, InputAdornment } from '@mui/material';
import { MdClose } from 'react-icons/md';

const ChipsInput = ({chips, setChips}) => {
  const [inputValue, setInputValue] = useState('');
  // const [chips, setChips] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const separators = /[,.\s]/;

    if (separators.test(value)) {
      const newChips = value.split(separators).filter(Boolean);
      setChips(newChips,1);
      setInputValue('');
    } else {
      setInputValue(value);
    }
  };

  const handleDeleteChip = (chipToDelete) => () => {
    setChips(chipToDelete, 2);    
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const value = event.target.value.trim();
      if (value) {
        setChips(value, 1);
        setInputValue('');
      }
      event.preventDefault();
    }
  };

  return (
    <Box  dir="rtl">
      <TextField
        label="أدخل القيم"
        variant="outlined"
        value={inputValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {chips?.map((chip, index) => (
                <Chip
                  key={index}
                  label={chip}
                  onDelete={handleDeleteChip(chip)}
                  deleteIcon={<MdClose />}
                  sx={{
                    margin: '2px',
                    display: 'flex',
                    paddingLeft : '8px',
                    alignItems: 'center',
                    '.MuiChip-deleteIcon': {
                      marginRight: '-8px', // Adjust this to align the delete icon better
                    },
                  }}
                />
              ))}
            </InputAdornment>
          ),
        }}
        inputProps={{
          style: { textAlign: 'right' },
        }}
      />
    </Box>
  );
};

export default ChipsInput;
