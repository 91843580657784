import { Button, useScrollTrigger } from "@mui/material";
import StarterKit from "@tiptap/starter-kit";
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor as RichTextEditors, RichTextReadOnly
} from "mui-tiptap";
import { useRef, useState } from "react";

export default function RichTextEditor({ description, setDescription, readOnly }) {
    const rteRef = useRef(null);
    return (
        <div dir='rtl' >
            {readOnly ?
                <RichTextReadOnly content={description} extensions={[StarterKit]} />
                :
                <RichTextEditors
                    ref={rteRef}
                    extensions={[StarterKit]} // Or any Tiptap extensions you wish!
                    content={description} // Initial content for the editor
                    // Optionally include `renderControls` for a menu-bar atop the editor:

                    onUpdate={() => setDescription(rteRef.current?.editor?.getHTML())}
                    renderControls={() => (
                        <MenuControlsContainer>
                            <MenuSelectHeading />
                            <MenuDivider />
                            <MenuButtonBold />
                            <MenuDivider />
                            <MenuButtonItalic />
                            <MenuDivider />
                        </MenuControlsContainer>
                    )}
                />
            }
        </div>
    );
}