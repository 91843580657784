// src/PrivacyPolicy.js
import React, { useEffect, useState } from 'react';
import { getStoreData } from '../../Constants/Constant';

const PrivacyPolicy = () => {
  const [inputs, setInputs] = useState({
    instagram: '',
    tiktok: '',
    facebook: '',
    pixel: '',
    x: '',
    phone1: '',
    phone2: '',
    phone3: '',
    description: '',
    email: '',
    address: ''
  });

  useEffect(() => {
    getStoreData(setInputs);
  }, []);
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>سياسة الخصوصية</h1>
      <p>{`نحن في ${process.env.REACT_APP_STORE_NAME} نقدر خصوصيتك ونلتزم بحماية بياناتك الشخصية. تهدف هذه السياسة إلى إعلامك بكيفية جمع واستخدام وحماية معلوماتك.`}</p>

      <h2 style={styles.subHeading}>جمع المعلومات</h2>
      <p>نقوم بجمع المعلومات الشخصية التي تقدمها لنا عند التسجيل في موقعنا أو عند استخدام خدماتنا. قد تشمل هذه المعلومات، على سبيل المثال لا الحصر، اسمك، عنوان بريدك الإلكتروني، ورقم هاتفك.</p>

      <h2 style={styles.subHeading}>استخدام المعلومات</h2>
      <p>نستخدم المعلومات التي نجمعها لتحسين خدماتنا وتخصيص تجربتك على موقعنا. قد نستخدم معلوماتك للتواصل معك، لتقديم الدعم الفني، أو لإرسال تحديثات هامة حول خدماتنا.</p>

      <h2 style={styles.subHeading}>مشاركة المعلومات</h2>
      <p>نحن لا نبيع أو نؤجر معلوماتك الشخصية لأطراف ثالثة. قد نشارك معلوماتك مع شركائنا الذين يساعدوننا في تشغيل موقعنا وتقديم خدماتنا، بشرط أن يلتزموا بحماية خصوصيتك.</p>

      <h2 style={styles.subHeading}>حماية المعلومات</h2>
      <p>نتخذ إجراءات أمنية لحماية معلوماتك الشخصية من الوصول غير المصرح به أو التغيير أو الكشف. ومع ذلك، لا يمكننا ضمان أمان المعلومات المرسلة عبر الإنترنت بشكل كامل.</p>

      <h2 style={styles.subHeading}>حقوقك</h2>
      <p>يحق لك الوصول إلى معلوماتك الشخصية التي نحتفظ بها وتصحيحها أو طلب حذفها. إذا كنت ترغب في ممارسة هذه الحقوق، يرجى الاتصال بنا عبر معلومات الاتصال أدناه.</p>

      <h2 style={styles.subHeading}>تغييرات على سياسة الخصوصية</h2>
      <p>قد نقوم بتحديث سياسة الخصوصية هذه من وقت لآخر. سنقوم بإعلامك بأي تغييرات جوهرية من خلال نشر السياسة الجديدة على موقعنا.</p>

      <h2 style={styles.subHeading}>اتصل بنا</h2>
      <p>إذا كان لديك أي أسئلة أو استفسارات حول سياسة الخصوصية هذه، يرجى الاتصال بنا على:</p>
      <ul>
        {inputs.email ? <li> <a href={`email:${inputs.email}`}>{`البريد الإلكتروني: ${inputs.email}`} </a></li> : null}
        {inputs.phone1 ? <li> <a href={`tel:${inputs.phone1}`}> {`الهاتف: ${inputs.phone1}`} </a></li> : null}
        {inputs.address ? <li>{`العنوان: ${inputs.address}`}</li> : null}
      </ul>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    direction: 'rtl',
    textAlign: 'right',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    backgroundColor: '#f9f9f9'
  },
  heading: {
    color: '#333'
  },
  subHeading: {
    color: '#333'
  }
};

export default PrivacyPolicy;
