import React, { useState } from 'react';
import {
    Box,
    TextField,
    IconButton,
    Grid,
} from '@mui/material';
import { MdClose } from 'react-icons/md';
import RichTextEditor from '../../../Components/RichTextEditor/RichTextEditor';


const SocialMediaInputs = ({ inputs, setInputs, socialMediaPlatforms }) => {


    const handleInputChange = (platform) => (event) => {
        setInputs({ ...inputs, [platform]: event.target.value });
    };

    const handleDeleteInput = (platform) => () => {
        setInputs({ ...inputs, [platform]: '' });
    };

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
                {socialMediaPlatforms.map((platform) => (
                    <Grid item xs={12} key={platform.name}>
                        <TextField
                            dir={platform?.dir ? platform.dir : "ltr"}
                            label={platform.label}
                            value={inputs[platform.name]}
                            onChange={handleInputChange(platform.name)}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <IconButton
                                        sx={{ color: 'inherit', mr: 1 }}
                                        disabled
                                    >
                                        {platform.icon}
                                    </IconButton>
                                ),
                                endAdornment: inputs[platform.name] && (
                                    <IconButton
                                        sx={{ color: 'inherit' }}
                                        onClick={handleDeleteInput(platform.name)}
                                    >
                                        <MdClose />
                                    </IconButton>
                                ),
                            }}
                        />

                    </Grid>
                ))}

                <Grid item xs={12} >
                    <h4 style={{ textAlign: "right" }} >وصف المتجر</h4>
                    <RichTextEditor readOnly={false} description={inputs.description} setDescription={(val) => {
                        console.log(val)
                        setInputs({ ...inputs, description: val })
                    }} />
                </Grid>
            </Grid>
        </Box>
    );
};

export default SocialMediaInputs;
