import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, Typography, Box, useMediaQuery, Grid } from '@mui/material';
import ProductChart from './Charts/ProductChart';
import axios from 'axios';
import ProductTable from './Tables/ProductTable';
import { VscGraph } from 'react-icons/vsc'
import { CgProfile } from 'react-icons/cg'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { FaShippingFast } from 'react-icons/fa'
import { TbReportMoney } from 'react-icons/tb'
import { BsShopWindow } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import OrderTable from './Tables/OrderTable';
import Widget from './Widget';
import UpdateStore from './UpdateStore';
import CategoriesTable from './Tables/CategoriesTable';
import ProductCharts from './Charts/ProductCharts';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}

        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography >{children} </Typography>

                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BasicTabs() {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categorieslabels, setCategoriesLabels] = useState([]);
    const [stat, setStat] = useState({ totalRevenue: 0, monthlyRevenue: 0, weeklyRevenue: 0, dailyRevenue: 0, totalOrders: 0, monthlyOrders: 0, weeklyOrders: 0, dailyOrders: 0 });
    useEffect(() => {
        getOrders();
        getProducts();
        getCategories();
        getCategoriesLabels();
        getStats();
    }, [])
    const getStats = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_GET_STATS, {
                headers: {
                    'Authorization': localStorage.getItem("Authorization")
                }
            });
            // console.log(data)
            setStat(data);
        } catch (error) {
            console.log(error);
            if(error.response.status === 401) {
                localStorage.removeItem('Authorization')
                toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
                navigate('/')
            }
        }
    }
    const getProducts = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_FETCH_PRODUCT);
            setProducts(data);
            /* setReview(data.review);
             setCart(data.cart);
             setWishlist(data.wishlist);
             setPaymentData(data.payment);*/
        } catch (error) {
            console.log(error);
        }
    }
    const getOrders = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_FETCH_ORDERS, {
                headers: {
                    'Authorization': localStorage.getItem("Authorization")
                }
            });
            // console.log(data)
            setOrders(data);
        } catch (error) {
            console.log(error);
            if(error.response.status === 401) {
                localStorage.removeItem('Authorization')
                toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
                navigate('/')
            }
        }
    }
    const getCategoriesLabels = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_GET_CATEGORIES_LABEL);
            setCategoriesLabels(data);
        } catch (error) {
            console.log(error);
        }
    }
    const getCategories = async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_GET_CATEGORIES);
            setCategories(data);
        } catch (error) {
            console.log(error);
        }
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const totalRevenue = 0;
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    return (
        <>
            <Box sx={{ width: '100%' }}>
                {localStorage.getItem('type') === '1' ? <Grid container spacing={2} direction={isSmallScreen ? 'column' : 'row'} padding={1}>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.totalRevenue} heading='الإيرادات الإجمالية' color='#9932CC' icon={<TbReportMoney />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.monthlyRevenue} heading='الإيرادات الشهرية' color='#FFC300' icon={<AiOutlineShoppingCart />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.weeklyRevenue} heading='الإيرادات الأسبوعية' color='#FF69B4' icon={<CgProfile />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.dailyRevenue} heading='الإيرادات اليوم' color='#1f77b4  ' icon={<FaShippingFast />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.totalOrders} heading='الطلبيات الإجمالية' color='#9932CC' icon={<TbReportMoney />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.monthlyOrders} heading='الطلبيات الشهرية' color='#FFC300' icon={<AiOutlineShoppingCart />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.weeklyOrders} heading='الطلبيات الأسبوعية' color='#FF69B4' icon={<CgProfile />} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <Widget numbers={stat?.dailyOrders} heading='الطلبيات اليوم' color='#1f77b4  ' icon={<FaShippingFast />} />
                    </Grid>
                </Grid> : null}
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5, width: '100%' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ overflowX: "a" }} >
                    <Tab label={!isSmallScreen && "الطلبيات"} {...a11yProps(0)} iconPosition='start' icon={<FaShippingFast fontSize={32} />} />
                       {localStorage.getItem('type') === '1' ? <Tab label={!isSmallScreen && "المنتجات"} {...a11yProps(1)} iconPosition='start' icon={<AiOutlineShoppingCart fontSize={32} />} /> : null}
                       {localStorage.getItem('type') === '1' ? <Tab label={!isSmallScreen && "أنواع المنتجات"} {...a11yProps(2)} iconPosition='start' icon={<BiCategory fontSize={32} />} /> : null}
                       {localStorage.getItem('type') === '1' ?<Tab label={!isSmallScreen && "المتجر"} {...a11yProps(3)} iconPosition='start' icon={<BsShopWindow fontSize={32} />} /> : null}
                        {localStorage.getItem('type') === '1' ? <Tab label={!isSmallScreen && 'إحصائيات'}  {...a11yProps(4)} iconPosition='start' icon={<VscGraph fontSize={32} />} /> : null}

                    </Tabs>
                </Box>
            </Box >
            <TabPanel value={value} index={0}>
                <OrderTable data={orders} getOrders={getOrders} />
            </TabPanel>
            {localStorage.getItem('type') === '1' ? <TabPanel value={value} index={1}>
                <ProductTable data={products} getProducts={getProducts} categorylabels={categorieslabels} />
            </TabPanel> : null}
            {localStorage.getItem('type') === '1' ? <TabPanel value={value} index={2}>
                <CategoriesTable data={categories} getCategories={getCategories} />
            </TabPanel> : null}
            {localStorage.getItem('type') === '1' ? <TabPanel value={value} index={3}>
                <UpdateStore />
            </TabPanel> : null}
            {localStorage.getItem('type') === '1' ? <TabPanel value={value} index={4} >
                <ProductCharts statschart={stat?.statschart} />
            </TabPanel> : null}
        </>
    );
}