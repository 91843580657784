import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { Container } from '@mui/material';
import BasicTabs from '../Components/AdminTabs';
import CopyRight from '../../Components/Footer/Footer'
const AdminHomePage = () => {

    return (
        <>
            <Container maxWidth="100%">
                <h1 style={{ textAlign: "center", margin: "20px 0", color: "#1976d2", fontSize: '46px', fontWeight: 'bolder' }}>لــوحة التحــكم </h1>
                <BasicTabs />
            </Container>
            <CopyRight sx={{ mt: 8, mb: 10 }} />

        </>
    )
}

export default AdminHomePage