import React, { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Container,
    InputAdornment,
    TextField,
    Skeleton, Button

}
    from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getStore } from '../../Constants/Constant';
import { MdOutlineCancel, MdPersonAddAlt1 } from 'react-icons/md';
import SocialMediaInput from './Tables/SocialMediaInput';
import SocialMediaInputs from './Tables/SocialMediaInput';
import { BsShopWindow } from 'react-icons/bs';
import { FaSave } from 'react-icons/fa';
import { MdClose, MdPhone } from 'react-icons/md';
import { FaInstagram, FaTiktok, FaFacebook, FaMailBulk, FaAddressCard } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const socialMediaPlatforms = [
    { name: 'instagram', label: 'حساب إنستغرام', icon: <FaInstagram /> },
    { name: 'tiktok', label: 'حساب تيك توك', icon: <FaTiktok /> },
    { name: 'facebook', label: 'حساب فيسبوك', icon: <FaFacebook /> },
    { name: 'pixel', label: 'فيسبوك بيكسل', icon: <FaFacebook /> },
    { name: 'x', label: 'حساب X', icon: <FaXTwitter /> },
    { name: 'phone1', label: 'رقم الهاتف 1', icon: <MdPhone /> },
    { name: 'phone2', label: 'رقم الهاتف 2', icon: <MdPhone /> },
    { name: 'phone3', label: 'رقم الهاتف 3', icon: <MdPhone /> },
    { name: 'email', label: 'البريد الإلكتروني', icon: <FaMailBulk /> },
    { name: 'address', label: 'العنوان', icon: <FaAddressCard />, dir: 'rtl' },
];
const UpdateStore = () => {
    const navigate = useNavigate();
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [inputs, setInputs] = useState({
        instagram: '',
        tiktok: '',
        facebook: '',
        pixel: '',
        x: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: '',
        email: '',
        address: ''
    });

    useEffect(() => {
        getStore(setInputs, setLoading);
    }, []);
    const handleSubmit = async () => {
        try {
            const { data } = await axios.post(process.env.REACT_APP_ADMIN_UPDATE_STORE,
                {
                    description: inputs.description,
                    instagram: inputs.instagram,
                    tiktok: inputs.tiktok,
                    facebook: inputs.facebook,
                    pixel: inputs.pixel,
                    x: inputs.x,
                    phone1: inputs.phone1,
                    phone2: inputs.phone2,
                    phone3: inputs.phone3,
                    email: inputs.email,
                    address: inputs.address
                },
                {
                    headers: {
                        'Authorization': localStorage.getItem("Authorization")
                    }
                });
            if (data.success) {
                getStore(setInputs, setLoading);
                toast.success("تم تحيين المتجر بنجاح", { autoClose: 500, theme: 'colored' });
            } else {
                toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' });
            }
        } catch (error) {
            console.log(error);
            if(error.response.status === 401) {
                localStorage.removeItem('Authorization')
                toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
                navigate('/')
            }
            toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' });
        }
    }
    return (
        <>
            <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginTop: 5 }}>
                {loading ? (
                    <Skeleton variant='rectangular' height={400} />
                ) : (
                    <SocialMediaInputs inputs={inputs} setInputs={setInputs} socialMediaPlatforms={socialMediaPlatforms} />
                )}
                
            </Container>
            <Button style={{ width: '30%', display: 'flex', justifyContent: 'center', margin: 'auto', fontSize: 22, color: 'white' }} type="submit" onClick={handleSubmit} variant="contained" startIcon={<FaSave color='white' size='1.5em' />}>حفظ</Button>


        </>

    )
}

export default UpdateStore