import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { FcOk, FcCancel } from "react-icons/fc";

import Label from '../label';
import Iconify from '../iconify';
import { Rating, Tooltip } from '@mui/material';
import { MdFormatListBulletedAdd } from "react-icons/md";
import { FcMissedCall } from "react-icons/fc";

// ----------------------------------------------------------------------

export default function OrderTableRow({
  selected, productsurl,
  name, address, phoneNumber, quantity, totalAmount, isDelivered, isConfirmed, product, setReturnOrder,
  deleteItem, setData, confirmDelivery, confirmOrder, createdAt, setClientDidntPickUp, clientDidntPickedUp, confirmedBy, orderReturned,
  _id
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    event.preventDefault();
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  return (
    <>
      <TableRow hover tabIndex={-1} selected={selected} style={(clientDidntPickedUp && !isConfirmed) ? { backgroundColor: 'tomato' } : (orderReturned && !isDelivered) ? { backgroundColor: 'lightgreen' } : null}
      >
        <TableCell align="right">{name}</TableCell>
        <TableCell align="right">{address}</TableCell>
        <TableCell align="center">{phoneNumber}</TableCell>
        <TableCell align="center" >{product}</TableCell>
        <TableCell align="center" style={{ fontWeight: 'bolder', fontSize: 18 }} >{quantity}</TableCell>
        <TableCell align="center" style={{ fontWeight: 'bolder', fontSize: 18 }} >{new Date(createdAt).toISOString()}</TableCell>
        <TableCell align="center" style={{ fontWeight: 'bolder', fontSize: 18, color: clientDidntPickedUp ? 'white' : 'tomato' }} dir='rtl'>  {`${totalAmount ? totalAmount : 0} د.ج `}</TableCell>
        <TableCell align="center" style={{ fontWeight: 'bolder', fontSize: 14 }} dir='rtl'>  {confirmedBy ? confirmedBy?.split('@')[0] : ''} </TableCell>

        <TableCell align="right" style={{ fontWeight: 'bolder', fontSize: 18, color: clientDidntPickedUp ? 'white' : 'tomato' }} >
          <Tooltip title={isConfirmed ? 'إلغاء الطلبية تأكيد' : 'تأكيد الطلبية'}>
            <IconButton disabled={isDelivered} aria-label="delete" onClick={(e) => {
              e.preventDefault();
              confirmOrder(!isConfirmed, _id)
            }} >
              {isConfirmed ? <FcCancel size='1.2em' /> : <FcOk size='1.2em' />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="right" style={{ fontWeight: 'bolder', fontSize: 18, color: clientDidntPickedUp ? 'white' : 'tomato' }} >
          <Tooltip title={isDelivered ? 'إلغاء توصيل الطلبية' : 'تأكيد توصيل الطلبية'}>
            <IconButton disabled={!isConfirmed} aria-label="delete" onClick={(e) => {
              e.preventDefault();
              confirmDelivery(!isDelivered, _id)
            }} >
              {isDelivered ? <FcCancel size='1.2em' /> : <FcOk size='1.2em' />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow >
      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        {!isConfirmed && !isDelivered ? <MenuItem onClick={(e) => {
          e.preventDefault();
          setClientDidntPickUp(_id)
        }} >
          <Iconify icon="flat-color-icons:missed-call" sx={{ mr: 2 }} />
          الزبون لايجيب
        </MenuItem> : null}
        {isConfirmed && !isDelivered ? <MenuItem onClick={(e) => {
          e.preventDefault();
          setReturnOrder(_id)
        }} >
          <Iconify icon="mdi:cash-return" sx={{ mr: 1 }} />
          إسترجاع الطلبية
        </MenuItem> : null}
        <MenuItem onClick={(e) => {
          e.preventDefault();
          deleteItem(_id)
        }} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          حذف
        </MenuItem>
        <MenuItem onClick={(e) => {
          e.preventDefault();
          window.open(productsurl)
        }} >
          <Iconify icon="emojione-v1:page" sx={{ mr: 1 }} />
          صفحة المنتج
        </MenuItem>
      </Popover>
    </>
  );
}
