import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import CategoryCard from '../../Components/Category_Card/CategoryCard';
import { fetchCategories } from '../../Helpers/HomePageBanner';
import Carousel from '../../Components/Carousel/Carousel'
import SearchBar from '../../Components/SearchBar/SearchBar'
import CopyRight from '../../Components/Footer/Footer'
import { getStoreData } from '../../Constants/Constant'
import RichTextEditor from '../../Components/RichTextEditor/RichTextEditor'
const HomePage = () => {
    const [inputs, setInputs] = useState({
        instagram: '',
        tiktok: '',
        facebook: '',
        pixel: '',
        x: '',
        phone1: '',
        phone2: '',
        phone3: '',
        description: ''
    });

    const [categories, setCategories] = useState([]);
    useEffect(() => {
        // Fetch categories data && the store despription when the component mounts
        fetchData();
        getStoreData(setInputs);

    }, []);

    const fetchData = async () => {
        const data = await fetchCategories();
        setCategories(data);
    };
    /* useEffect(() => {
         window.scroll(0, 0)
     }, [])
     const getCart = async () => {
         if (authToken !== null) {
             const { data } = await axios.get(`${process.env.REACT_APP_GET_CART}`,
                 {
                     headers: {
                         'Authorization': authToken
                     }
                 })
             setCart(data);
         }
 
     }
     */


    return (
        <>
            <Container maxWidth='xl' style={{ display: 'flex', justifyContent: "center", padding: 0, flexDirection: "column", marginBottom: 70 }}>
                <Box padding={1}>
                    <Carousel />
                </Box>
                <RichTextEditor readOnly={true} description={inputs.description}  />
                <Typography variant='h3' sx={{ textAlign: 'center', marginTop: 10, color: '#1976d2', fontWeight: 'bold' }}>أنواع المنتجات</Typography>
                {categories?.length > 0 && <Container maxWidth='xl' style={{ marginTop: 90, display: "flex", justifyContent: 'center', flexGrow: 1, flexWrap: 'wrap', gap: 20, }}>
                    {
                        categories?.map(data => (
                            <CategoryCard data={data} key={data.img} />
                        ))
                    }
                </Container>}
            </Container >
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </ >
    )
}

export default HomePage