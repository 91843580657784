import React, { useEffect, useState } from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, Backdrop, CircularProgress, Rating,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Transition, convertToBase64 } from '../../Constants/Constant';
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import MyPreviewDropZone from '../../Components/MyPreviewDropZone/MyPreviewDropZone';
import AsyncSelect from './AsyncSelect/AsyncSelect';
import { width } from '@fortawesome/free-brands-svg-icons/fa42Group';
import RichTextEditor from '../../Components/RichTextEditor/RichTextEditor';
import { useNavigate } from 'react-router-dom';
const arabic = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\u0660-\u0669\s]+$/
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const AddProduct = ({ open, setOpen, getProducts, data, title, categorylabels }) => {
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [base64, setBase64String] = useState([]);
    // const [open, setOpen] = useState(false);

    let authToken = localStorage.getItem("Authorization")
    const [product, setProduct] = useState({
        name: '', name_fr: '', open: false, id: '', new_price: 0, price: 0, description: '', weight: 0, lngth: 0, height: 0, width: 0, rating: 0
        , category: ''
    });
    // const [Product, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
    const handleOnchange = (e) => {

            setProduct({ ...product, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen(false);
        setProduct({
            name: '', name_fr: '', open: false, id: '', new_price: 0, price: 0, description: '', weight: 0, lngth: 0, height: 0, width: 0, rating: 0
            , category: ''
        });
        setFiles([]);
        setBase64String([]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setProduct({ ...product, open: true });
        // console.log(product)
        try {
            if (files.length === 0 || !product.name || !product.name_fr || !product.price || !product.description || !product.rating) {
                toast.warning("الرجاء إكمال المعلومات", { autoClose: 500, theme: 'colored' });
            } else {
                const { data } = await axios.post(process.env.REACT_APP_ADMIN_ADD_PRODUCT,
                    {
                        name: product.name,
                        name_fr: product.name_fr,
                        image: base64?.length > 0 ? base64 : files,
                        price: product.price,
                        new_price: product.new_price,
                        lngth: product.lngth,
                        width: product.width,
                        height: product.height,
                        weight: product.weight,
                        category: product.category?.name_fr,
                        rating: product.rating,
                        description: product.description,
                        id: product?._id
                    }, {
                    headers: {
                        'Authorization': localStorage.getItem("Authorization")
                    }
                })
                setProduct({ ...product, open: false });
                if (data.success) {
                    getProducts();
                    setOpen(false)
                    toast.success("تم إضافة منتج جد بنجاح", { autoClose: 500, theme: 'colored' });
                    setProduct({
                        name: '', name_fr: '', open: false, id: '', new_price: 0, price: 0, description: '', weight: 0, lngth: 0, height: 0, width: 0, rating: 0
                        , category: ''
                    });
                    setFiles([]);
                    setBase64String([]);
                }
                else {
                    toast.error(data.msg, { autoClose: 500, theme: 'colored' });
                }
            }

        } catch (error) {
            setProduct({ ...product, open: false });
            console.log(error);
            if(error?.response?.status === 401) {
                localStorage.removeItem('Authorization')
                toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
                navigate('/')
            } else
            toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
        }
    }
    useEffect(() => {
        getProductById(data?._id)
    }, [data])
    const getProductById = async (id) => {
        if (id) {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_FETCH_SINGLE_PRODUCT}/${id}`);
                // console.log(data)
                setProduct({ ...data, category: data.category });
                setFiles(data.image)
                /* setReview(data.review);
                 setCart(data.cart);
                 setWishlist(data.wishlist);
                 setPaymentData(data.payment);*/
            } catch (error) {
                console.log(error);
            }
        }
    }
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                open={product.open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Divider sx={{ mb: 5 }} />
            <Dialog style={{ minWidth: '300px' }}
                open={open}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField dir='rtl' label="التسمية" name='name' value={product.name} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField label="libellé" name='name_fr' value={product.name_fr} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} >
                                    <h4 style={{ textAlign: "right" }} > التعريف بالمنتج</h4>
                                    <RichTextEditor readOnly={false} description={product.description} setDescription={(val) => {
                                        setProduct({ ...product, description: val })
                                    }} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="السعر الجديد" name='new_price' value={product.new_price} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField label="السعر" name='price' value={product.price} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField label="الطول" name='lngth' value={product.lngth} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField label="العرض" name='width' value={product.width} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField label="العمق" name='height' value={product.height} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField label="الوزن" name='weight' value={product.weight} onChange={handleOnchange} variant="outlined" fullWidth inputMode='numeric' />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Rating
                                        style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}
                                        name="simple-controlled"
                                        value={product.rating}
                                        onChange={(event, newValue) => {
                                            setProduct({ ...product, rating: newValue });
                                        }}
                                        precision={1}
                                        size='large'
                                    />

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <AsyncSelect value={product.category} label='نوع المنتج' data={categorylabels} setValue={(val) => setProduct({ ...product, category: val })} />
                                </Grid>
                                <MyPreviewDropZone files={files} setFiles={setFiles} setBase64={setBase64String} maximaged={10} />
                            </Grid>
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button style={{ color: 'white' }} fullWidth variant='contained' type='reset' color='error' onClick={handleClose} startIcon={<MdOutlineCancel color='white' />}>إلفاء</Button>
                                <Button disabled={product.open || files.length === 0 || !product.name || !product.name_fr || !product.price || !product.description || !product.rating} style={{ color: 'white' }} type="submit" fullWidth variant="contained" startIcon={<MdProductionQuantityLimits color='white' />}>تأكيد</Button>
                            </DialogActions>

                        </form>
                    </Box >

                </DialogContent>
            </Dialog >
        </>
    )
}

export default AddProduct