import React, { useEffect, useState } from 'react'
import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, Backdrop, CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Transition, convertToBase64 } from '../../Constants/Constant';
import { MdOutlineCancel, MdProductionQuantityLimits } from 'react-icons/md';
import MyPreviewDropZone from '../../Components/MyPreviewDropZone/MyPreviewDropZone';
import { useNavigate } from 'react-router-dom';
const arabic = /^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF0-9\u0660-\u0669\s]+$/
;
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
const AddCategory = ({ open, setOpen, getCategories, data, title }) => {
    const navigate = useNavigate();
    const [files, setFiles] = useState([]);
    const [base64, setBase64String] = useState([]);
    // const [open, setOpen] = useState(false);

    let authToken = localStorage.getItem("Authorization")
    const [category, setCategory] = useState({
        name: '', name_fr: '', open: false, id : ''
    });
    // const [category, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
    const handleOnchange = (e) => {
            setCategory({ ...category, [e.target.name]: e.target.value })
    }

    const handleClose = () => {
        setOpen(false);
        setCategory({
            name: "",
            name_fr: ""
        });
        setFiles([]);
        setBase64String([]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setCategory({ ...category, open: true })
        try {
            if (files.length === 0 || !category.name || !category.name_fr) {
                toast.warning("الرجاء إكمال المعلومات", { autoClose: 500, theme: 'colored' });
            } else {
                const { data } = await axios.post(process.env.REACT_APP_ADMIN_ADD_CATEGORY,
                    {
                        name: category.name,
                        name_fr: category.name_fr,
                        img: base64?.length > 0 ? base64 : files, 
                        id : category.id
                    }, {
                    headers: {
                        'Authorization': localStorage.getItem("Authorization")
                    }
                })
                setCategory({ ...category, open: false });
                if (data.success) {
                    getCategories();
                    setOpen(false)
                    toast.success("تم إضافة نوع جديد من المنتجات بنجاح", { autoClose: 500, theme: 'colored' });
                    setCategory({
                        name: "",
                        name_fr: ""
                    });
                    setFiles([]);
                    setBase64String([]);
                }
                else {
                    toast.error(data.msg, { autoClose: 500, theme: 'colored' });
                }
            }

        } catch (error) {
            setCategory({ ...category, open: false });
            console.log(error);
            if(error.response.status === 401) {
                localStorage.removeItem('Authorization')
                toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
                navigate('/')
            } else
            toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
        }
    }
    useEffect(() => {
        setCategory({ ...category, name: data?.name, name_fr: data?.name_fr, id : data?._id });
        setFiles([data?.img])
    }, [data])
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
                open={category.open}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <Divider sx={{ mb: 5 }} />
            <Dialog style={{ minWidth: '300px' }}
                open={open}
                onClose={handleClose}
                keepMounted
                TransitionComponent={Transition}>
                <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>{title}</DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField dir='rtl' label="التسمية" name='name' value={category.name} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField label="libellé" name='name_fr' value={category.name_fr} onChange={handleOnchange} variant="outlined" fullWidth />
                                </Grid>
                                <MyPreviewDropZone files={files} setFiles={setFiles} setBase64={setBase64String} />
                            </Grid>
                            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                <Button style={{ color: 'white' }} fullWidth variant='contained' type='reset' color='error' onClick={handleClose} startIcon={<MdOutlineCancel color='white' />}>إلفاء</Button>
                                <Button disabled={category.open || files.length === 0 || !category.name || !category.name_fr} style={{ color: 'white' }} type="submit" fullWidth variant="contained" startIcon={<MdProductionQuantityLimits color='white' />}>تأكيد</Button>
                            </DialogActions>

                        </form>
                    </Box >

                </DialogContent>
            </Dialog >
        </>
    )
}

export default AddCategory