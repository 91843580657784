import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Container,
  InputAdornment,
  TextField, Card, Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Backdrop, CircularProgress, Typography
}
  from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Transition } from '../../../Constants/Constant';

import { Link, useNavigate } from 'react-router-dom';
import Scrollbar from './scrollbar';
import AddCategory from '../AddCategory';
import TableNoData from './tools/table-no-data';
import UserTableRow from './tools/category-table-row';
import UserTableHead from './tools/category-table-head';
import TableEmptyRows from './tools/table-empty-rows';
import UserTableToolbar from './tools/category-table-toolbar';
import { emptyRows, applyFilter, getComparator } from './tools/utils';
import { MdOutlineCancel, MdProductionQuantityLimits, MdAdd } from 'react-icons/md';

const CategoriesTable = ({ data, getCategories }) => {
  const navigate = useNavigate();
  const [openupdate, setOpenUpdate] = useState(false);
  const [dataItem, setDataItem] = useState();
  const [operation, setOperation] = useState(1) // 1 for add 2 for update
  const [loading, setLoading] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filterData = () => {
    if (searchTerm === '') {
      return data;
    }
    return data.filter(
      (item) =>
        (item.name &&
          item.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
        (item.name_fr &&
          item.name_fr.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    const newFilteredData = filterData();
    setFilteredData(newFilteredData);
  };

  useEffect(() => {
    setFilteredData(filterData());
  }, [data, searchTerm]);




  const [open, setOpen] = useState(false);
  const [id_delete, setIdDelete] = useState(false);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSort = (event, id) => {
    const isAsc = orderBy === id && order === 'asc';
    if (id !== '') {
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(id);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(order, orderBy),
    filterName,
  });

  const notFound = !filteredData.length;
  const deleteItem = (id) => {
    setOpen(true);
    setIdDelete(id);
  }
  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      const { data } = await axios.post(process.env.REACT_APP_ADMIN_DELETE_CATEGORY,
        {
          id: id_delete
        }, {
        headers: {
          'Authorization': localStorage.getItem("Authorization")
        }
      })
      setLoading(false);
      if (data.success) {
        getCategories();
        toast.success("تم الحذف بنجاح", { autoClose: 500, theme: 'colored' });
        setOpen(false);
      }
      else {
        toast.error(data.msg, { autoClose: 500, theme: 'colored' });
      }


    } catch (error) {
      setLoading(false);
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem('Authorization')
        toast.error("يرجى إعادة التسجيل", { autoClose: 500, theme: 'colored' })
        navigate('/')
      } else
        toast.error("يوجد خلل", { autoClose: 500, theme: 'colored' })
    }
  }
  const handleClose = () => {
    setOpen(false);

  };
  const setData = (data) => {
    setDataItem(data);
    setOpenUpdate(true);
  }
  const handleClickOpen = () => {
    setDataItem('');
    setOpenUpdate(true);
  };
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 5 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 5, marginTop: 5 }}>
        <TextField dir='rtl'
          id="search"
          type="search"
          label="البحث عن أنواع المنتجات"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: { xs: 350, sm: 500, md: 800 }, }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AiOutlineSearch />
              </InputAdornment>
            ),
          }}
        />
      </Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', margin: "20px 0" }} >
        <Button style={{ color: 'white' }} variant='contained' startIcon={<MdAdd color='white' />} onClick={handleClickOpen}>إضافة</Button>
        <Typography fontSize={28} variant='h6' textAlign='center' color="#1976d2" fontWeight="bold">{operation === 1 ? 'إضافة نوع جديد من المنتجات' : ''}</Typography>
      </Box>
      <AddCategory open={openupdate} setOpen={setOpenUpdate} getCategories={getCategories} data={dataItem} title={!dataItem ? 'إضافة نوع جديد من المنتجات' : 'تحيين نوع المنتج'} />
      <Dialog style={{ minWidth: '300px' }}
        open={open}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Transition}>
        <DialogTitle sx={{ textAlign: "center", fontWeight: 'bold', color: "#1976d2" }}>تأكيد حذف نوع المنتج</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <form onSubmit={handleSubmit}>

              <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                <Button style={{ color: 'white' }} fullWidth variant='contained' type='reset' color='error' onClick={handleClose} startIcon={<MdOutlineCancel color='white' />}>إلفاء</Button>
                <Button style={{ color: 'white' }} type="submit" fullWidth variant="contained" startIcon={<MdAdd color='white' />}>تأكيد</Button>
              </DialogActions>

            </form>
          </Box >

        </DialogContent>
      </Dialog >
      <Card>

        <Scrollbar>
          <TableContainer  >
            <Table sx={{ minWidth: 800 }}>
              <UserTableHead
                order={order}
                orderBy={orderBy}
                rowCount={data.length}
                numSelected={selected.length}
                onRequestSort={handleSort}
                onSelectAllClick={handleSelectAllClick}
                headLabel={[
                  { id: 'name_fr', label: 'Libellé' },
                  { id: 'img', label: 'Image' },
                  { id: 'name', label: 'التسمية', align: 'right' },
                  { id: 'count', label: 'عدد المنتجات', align: 'right' },
                  { id: '' },
                ]}
              />
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <UserTableRow
                      key={row.id}
                      name={row.name}
                      name_fr={row.name_fr}
                      img={row.img}
                      count={row.count}
                      _id={row?._id}
                      deleteItem={deleteItem}
                      setData={setData}
                      selected={selected.indexOf(row.name) !== -1}
                      handleClick={(event) => handleClick(event, row.name)}
                    />
                  ))}

                <TableEmptyRows
                  height={77}
                  emptyRows={emptyRows(page, rowsPerPage, data.length)}
                />

                {notFound && <TableNoData query={filterName} />}
              </TableBody>
            </Table>
            <TablePagination
              page={page}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              rowsPerPageOptions={[5, 10, 25]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Scrollbar>


      </Card>
    </>
  )
}

export default CategoriesTable