import './tabs.css';
import React, { useContext, useEffect, useState, useRef } from 'react';
import Typography from '@mui/material/Typography';
import SmallTab from './SmallTab';
const Tabs = ({ variants, setVariants }) => {
    const [state, setState] = useState({ radio1: false });
    const handleChangle = (e) => {
        const name = e.target.name;
        const checked = e.target.checked;
        setState({
            [name]: checked
        });
        setVariants(name)

    }
    return (
        variants?.length > 0 ?
            variants.map((val) => (
                <Variant val={val} setVariants = {setVariants} />
            )) : null
    )
}
const Variant = ({ val,setVariants }) => {
    const [state, setState] = useState({ radio1: false });
    const handleChangle = (e, choosed) => {
        const name = e.target.name;
        const checked = e.target.checked;
        setState({
            [name]: checked
        });
        setVariants({name : val?.name, val : choosed})

    }
    return (
        <>
            <Typography variant="h6" textAlign={'right'} >
                الرجاء إختيار {val.name} :
            </Typography>
            <div className="radio-inputs">
                {val?.variants?.map((item, index) =>
                (<label className="radio">
                    <input type="radio" name={`radio${index}`} checked={eval(`state.radio${index}`)} onClick={(e) => handleChangle(e, item)} />
                    <span className="name">{item}</span>
                </label>)
                )}
            </div>

        </>
    )
}
/*
<div className="radio-inputs">
                        <label className="radio">
                            <input type="radio" name="radio1" checked={state.radio1} onChange={handleChangle} />
                            <span className="name">HTML</span>
                        </label>
                        <label className="radio">
                            <input type="radio" name="radio2" checked={state.radio2} onChange={handleChangle} />
                            <span className="name">React</span>
                        </label>

                        <label className="radio">
                            <input type="radio" name="radio3" checked={state.radio3} onChange={handleChangle} />
                            <span className="name">Vue</span>
                        </label>
                    </div>
*/
export default Tabs