import React, { PureComponent } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Container } from '@mui/material';

export default function ProductCharts({ statschart }) {

  return (
    <>
      <Container sx={{ marginTop: 5, }}>

        <h3 style={{ textAlign: "center", margin: "20px 0", color: "#8884d8" }}>المبيعات والإيرادات المحققة</h3>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer >
            <BarChart
              width={500}
              height={300}
              data={statschart}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="category" />
              <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
              <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
              <Tooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="totalRevenue" fill="#8884d8" />
              <Bar yAxisId="right" dataKey="totalOrders" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </Container>
    </>
  );
}
