import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css'; // Import your CSS file for styling
import { ReactComponent as Instagram } from '../../Assets/instagram.svg';
import { ReactComponent as Tiktok } from '../../Assets/tiktok.svg';
import { ReactComponent as Faceboook } from '../../Assets/facebook.svg';
import { ReactComponent as Phone } from '../../Assets/phone.svg';
import { Link } from 'react-router-dom';
import { getStore, getStoreData } from '../../Constants/Constant';
const styles = {
  footer: {
    backgroundColor: '#333',
    color: '#fff',
    textAlign: 'center',
    padding: '10px 0',
    position: 'fixed',
    width: '100%',
    bottom: 0,
  },
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '0 20px'
  },
  link: {
    color: '#AAAA',
    textDecoration: 'none',

  },
};

const Footer = () => {
  const [inputs, setInputs] = useState({
    instagram: '',
    tiktok: '',
    facebook: '',
    pixel: '',
    x: '',
    phone1: '',
    phone2: '',
    phone3: '',
    description: ''
  });

  useEffect(() => {
    getStoreData(setInputs);
  }, []);
  return (
    <footer className="footer">
      <div className="social-icons">
        {inputs.instagram ? <a href={inputs.instagram}><Instagram style={{ width: '42px', height: '42px', marginRight: '10px' }} /></a> : null}
        {inputs.tiktok ? <a href={inputs.tiktok}><Tiktok style={{ width: '42px', height: '42px', marginRight: '10px' }} /></a> : null}
        {inputs.facebook ? <a href={inputs.facebook}><Faceboook style={{ width: '42px', height: '42px', marginRight: '10px' }} /></a> : null}
        {inputs.phone1 ? <a href={`tel:${inputs.phone1}`}><Phone style={{ width: '42px', height: '42px', marginRight: '10px' }} /></a> : null}
      </div>
      <div style={styles.container}>
        <p style={styles.text}> {`2024 © ${process.env.REACT_APP_STORE_NAME}. جميع الحقوق محفوظة.`}</p>
        <p style={styles.text}>
          <Link to="/privacy-policy" style={styles.link}>سياسة الخصوصية</Link> |
          <Link to="/shipping-return-policy" style={styles.link}>سياسة الشحن والإرجاع</Link>
        </p>
      </div>


    </footer>
  );
};

export default Footer;
