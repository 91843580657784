import axios from 'axios';

// Function to fetch categories data
export const fetchCategories = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_GET_CATEGORIES);
    return response.data; // Assuming the categories data is returned as an array
  } catch (error) {
    console.error('Error fetching categories:', error);
    return []; // Return an empty array in case of error
  }
};

/*

const data = [
    {
        img: GroupCloth,
        name: "Cloths"
    },
    {
        img: Shoes,
        name: "Shoe"
    },
    {
        img: FemaleCloth,
        name: "Cloths"
    },
    {
        img: Electronics,
        name: "Electronics"
    },
    {
        img: MaleCloth,
        name: "Cloths"
    },
    {
        img: Books,
        name: "Book"
    },
    {
        img: Jewelry,
        name: "Jewelry"
    },
]*/