import './AddVariantButton.css'
const AddVariantButton = ({ onClicked, enable, title }) => {
    return (
        <button class="button11 learn-more" onClick={(e) => {e.preventDefault(); onClicked()}} >
            <span class="circle" aria-hidden="true">
                <span class="icon arrow"></span>
            </span>
            <span class="button11-text">{title}</span>
        </button>

    )
}

export default AddVariantButton
