import { useState } from 'react';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Label from '../label';
import Iconify from '../iconify';
import { Rating } from '@mui/material';
import { MdFormatListBulletedAdd } from "react-icons/md";

// ----------------------------------------------------------------------

export default function ProductTableRow({
  selected,
  name, name_fr,
  rating,
  price, category,
  deleteItem, setData, setDataVariant,
  _id
}) {
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  return (
    <>
      <TableRow hover tabIndex={-1} selected={selected}>

        <TableCell align="left">{name_fr}</TableCell>

        <TableCell align="right">{name}</TableCell>

        <TableCell align="center">
          <Rating size='small' name="read-only" value={rating ? Math.round(rating) : 0} readOnly precision={1} />
        </TableCell>


        <TableCell align="right" style={{ fontWeight: 'bolder', fontSize: 18 }} dir='rtl'  >  {`${price} د.ج `}</TableCell>

        <TableCell align="right"  >{category}</TableCell>

        <TableCell align="right">
          <IconButton onClick={handleOpenMenu}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: { width: 140 },
        }}
      >
        <MenuItem onClick={() => setData({ _id: _id })}>
          <Iconify icon="eva:edit-fill" sx={{ mr: 2 }} />
          تحيين
        </MenuItem>

        <MenuItem onClick={() => deleteItem(_id)} sx={{ color: 'error.main' }}>
          <Iconify icon="eva:trash-2-outline" sx={{ mr: 2 }} />
          حذف
        </MenuItem>
        <MenuItem onClick={() => {
          setDataVariant({ _id: _id })
        }}  >
          <Iconify
            icon="icon-park:category-management" sx={{ mr: 2 }} />
          الأصناف
        </MenuItem>
      </Popover>
    </>
  );
}
