import * as React from 'react';
import AliceCarousel from 'react-alice-carousel';

import {
  Box,Paper,
  Button,
  Container,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Chip,
  Rating,
  ButtonGroup, CircularProgress, Backdrop,
  Skeleton,
  IconButton, TextField, OutlinedInput, InputLabel, InputAdornment, FormControl, Select, MenuItem, Alert, Snackbar
} from '@mui/material';
function SwipeableTextMobileStepper({images}) {
  const responsive = {
    0: { items: 1 },
    568: { items: 1, itemsFit: 'contain'  },
    1024: { items: 1, itemsFit: 'contain' },
};
const items = images?.map((item) => (

        <div className="item" style={{ marginTop: 10 }} >
            <img src={item} loading='lazy' alt={item.name} style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
        </div>
))

  return (
   
    <AliceCarousel
    animationType="fadeout"
    animationDuration={800}
    disableButtonsControls
    infinite
    items={items}
    touchTracking
    mouseTracking
    disableDotsControls
    autoPlay
    autoPlayInterval={2500}
    responsive={responsive}
    
/>
  );
}

export default SwipeableTextMobileStepper;
