import './butbutton.css'
const BuyButton = ({ onClicked, enable, title, product }) => {
    return (
        <button className='button1' dir='ltr' disabled={!enable} onClick={onClicked} color='#64B5F6' >

            <svg
                class="empty" height="32" width="32" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill="#64B5F6"
                    d="M4 .75H1a1 1 0 00-1 1v.5a1 1 0 001 1h2.012l2.724 11.481A4.25 4.25 0 009.765 18V18h7.822a4 4 0 003.943-3.325l1.256-7.338A2 2 0 0020.814 5H5.997l-.78-3.289A1.25 1.25 0 004 .75zM10 21a2 2 0 11-4 0 2 2 0 014 0zM21 21a2 2 0 11-4 0 2 2 0 014 0z"
                ></path>
            </svg>
            {title}
        </button>

    )
}

export default BuyButton